.storybook-button {
  font-family: 'Nunito Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-weight: 700;
  border: 0;
  border-radius: 3em;
  cursor: pointer;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    margin-right: 10px;
    height: 17px;
  }
}
.storybook-button--secondary:hover{
    background-color: #c52131;
    color: white;
}
.storybook-button--primary {
  color: white;
  background-color: #c52131;
}
.storybook-button--secondary {
  color: #c52131;
  background-color: transparent;
  border: 1px solid #c52131;
}
.storybook-button--small {
  font-size: 12px;
  padding: 10px 16px;
}
.storybook-button--medium {
  font-size: 14px;
  padding: 11px 20px;
}
.storybook-button--large {
  font-size: 16px;
  padding: 12px 24px;
}
.storybook-button:active {
  color: #c52131;
}
