body {
  background: #fffafb 0% 0% no-repeat padding-box;
}
.userPageContent {
  margin: 0;
  padding: 0;
  float: left;
  width: 100%;
  height: 100%;
}
.userPageCard {
  position: absolute;
  top: 111px;
  left: 281px;
  margin-left: 0px;
  max-width: calc(100% - 310px);
  width: 100%;
  min-height: 1134px;
  height: 80%;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 10px #00000005;
  border-radius: 10px;
  z-index: 1;
}
.userCardBody {
  margin-top: 53px;
  margin-left: 29px;
  width: 100%;
}
.userLabelText {
  text-align: left;
  font: normal normal 600 20px/30px Poppins;
  letter-spacing: 0px;
  color: #181c32;
  display: inline-block;
}
.filterButtons {
  display: inline-block;
}
.filterButtons:hover .tooltipText {
  visibility: visible;
}
.filterButtons .tooltipText {
  visibility: hidden;
  width: 102px;
  background-color: black;
  font: normal normal normal 14px/21px Poppins;
  color: #fff;
  text-align: center;
  border-radius: 5px;
  padding: 4px;
  z-index: 1;
}
#custom {
  display: inline-block;
  width: 90px;
}

#today {
  display: inline-block;
  width: 90px;
  margin-right: 15px;
}

#all {
  width: 80px;
  display: inline-block;
  margin-right: 15px;
}
.ag-theme-balham {
  margin-top: 30px;
  width: 100%;
  margin-bottom: 30px;
}
.ag-theme-balham .ag-header-cell-label {
  font: normal normal 600 14px/21px Poppins;
  letter-spacing: 0px;
  color: #181c32;
  opacity: 1;
}
 .mainPa{
    margin: -5% 70%;
    padding: 5%;
    font: normal normal normal 14px/21px Poppins;
  font-size: 20px;
      color: #A1A5B7;
  }
   .container {
    border-bottom: #fff 2px ridge;
   }
   .disabledPagination{
     color: #e3e4e9;
   }
   .pagination {
    margin-top: 15px;
    // margin-left: -40px;
    display: flex;
    list-style: none;
    outline: none;
   }
   .pagination>.active>a {
    background: #FBEDEF 0% 0% no-repeat padding-box;
    color: #c52131;
    width: 24px;
height: 24px;
border-radius: 3px;
   }
   .pagination>li>a {
    border: 1px ;
    padding: 5px 10px;
    outline: none;
    cursor: pointer;
   }

@media only screen and (min-width: 768px) {
  .userCardBody {
    max-height: 1134px;
    height: 80%;
    width: calc(100% - 58px);
  }
}
