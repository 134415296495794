body {
  background: #fffafb 0% 0% no-repeat padding-box;
}

.active {
  color: #25c997;
}

.inactive {
  color: #f64e60;
}

.customers_content {
  width: 100%;
  height: 100%;
}

.cardCustomers {
  position: absolute;
  top: 111px;
  left: 280px;
  margin-left: 0px;
  min-height: 1134px;
  height: 100%;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 10px #00000005;
  border-radius: 10px;
  opacity: 1;
  z-index: 1;
  .customers_card_body {
    text-align: left;
    padding-left: 1.5rem;
    margin-top: 47px;
    width: 100%;
  }

  .customers_label_text {
    text-align: left;
    font: normal normal 600 20px/24px Poppins;
    letter-spacing: 0px;
    color: #181c32;
    display: inline-block;
  }

  .searchFilter {
    display: inline-block;
    margin-left: 200px;
  }

  .sIcon {
    position: absolute;
    left:370px;
    top: 60px;
    width: 14px;
    height: 14px;
  }

  .search_bar {
    input:focus {
      outline: none;
    }

    justify-content: center;
    width: 400px;
    height: 42px;
    background: #f7f7f7 0% 0% no-repeat padding-box;
    border: 1px solid #eaeaea;
    border-radius: 3em;
    text-align: left;
    padding-left: 40px;
    font: normal normal normal 14px/21px Poppins;
    color: #7e8299;
    cursor: pointer;

  }
  input[type="submit"]{
    margin-left: -90px;
    height: 42px;
    width: 90px;
    background:transparent;
    font: normal normal bold 14px/21px Poppins;
    color: #c52131;
    border: 1px solid #c52131;
    border-radius: 3em;
   cursor: pointer;
    -webkit-appearance: none;
  }
  input [type="submit"]:hover{
      background-color: #c52131;
    color: white;
  }

  .searchFilter .tooltipText {
    visibility: hidden;
    width: 102px;
    background-color: black;
    font: normal normal normal 14px/21px Poppins;

    color: #fff;
    text-align: center;
    border-radius: 5px;
    padding: 4px;
    position: absolute;
    top: 10px;
    left: 400px;
    z-index: 1;
  }

  .searchFilter:hover .tooltipText {
    visibility: visible;
  }

  // .disableButton {
  //   background-color: rgba(0, 0, 0, .5);
  //   cursor: not-allowed;
  // }

  .enabledButton{
margin-top: 10px;
}
.enabledCButton{
  margin-top: 20px;
}
  .addCustomer {
    float: right;
      margin-right: 120px;
  }

  .deleteModal_titleEx{
    text-align: center;
    font: normal normal 600 19px/30px Poppins;
    letter-spacing: 0px;
    color: #181c32;
    padding-top: 39px;
    padding-left: 20px;
    padding-right: 14px;
  }


.edit_content{
  width: 340px;
  justify-content: center;
   margin: auto;
   display: block;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 16px;
  max-height: 340px;
  overflow: auto;
  height: 100%;
}
  .actionIcons button {
    width: 30px;
    height: 30px;
    border: white;
    border-radius: 3px;
    cursor: pointer;
  }
  .close{
    padding-top: 40px;
  padding-left: 165px;
  }
  .en{
    padding-top: 2px;
  }
  .cancelButton{
    margin-top: 2px;
  }

  .editButton {
    width: 30px;
    height: 30px;
    background-color: white;
  }

  .deleteButton {
    width: 30px;
    height: 30px;
    background: #FBEDEF 0% 0% no-repeat padding-box;
    margin-left: 10px;

  }

  .editButton #edit {
    width: 15px;
    height: 15px;
  }
  #edit path{
    fill: #f64e60;
  }

  .deleteButton #delete {
    width: 15px;
    height: 15px;
  }
   #delete path{
     fill: #f64e60;
   }

  .editButton:hover {
    background-color: #c52131;
    #edit path{
      fill: white;
    }
  }


  .deleteButton:hover {
    background-color: #c52131;
    #delete path{
      fill:white;
    }
  }

  .not-allowed {
    cursor: not-allowed;
  }

  .errorMessage {
    display: inline-block;
    color: #c52131;
    font: normal normal normal 14px/21px Poppins;
    margin-left: 4px;

  }

  .ag-theme-balham {
    margin-top: 30px;
    padding-left: 30px;
    width: 100%;
  }

  .ag-theme-balham .ag-header-cell-label {
    font: normal normal bold 14px/21px Poppins;
    color: black;
    border: none;
  }

  .ag-theme-balham .ag-header-cell {
    background-color: white;
  }

  .ag-theme-balham .ag-header-cell-label:active {
    color: #f64e60;
  }

  .ag-theme-balham .ag-header-cell-label:focus {
    color: #f64e60;
  }

  .ag-theme-balham .ag-header-cell-label:hover {
    color: #f64e60;
  }

  .ag-theme-balham .ag-paging-panel {
    position: inherit;
    color: #a1a5b7;
    margin-top: 20px;
    font: normal normal 600 10px/20px Poppins;
  }

  .ag-paging-button {
    color: #a1a5b7;
    width: 15px;
  }

  .ag-paging-row-summary-panel {
    text-align: left;
    padding-right: 550px;
    letter-spacing: 0px;
  }
  .mainPa{
    margin: -4% 70%;
    // border: 1px ridge #464141;
    padding: 5%;
    font: normal normal normal 14px/21px Poppins;
  font-size: 20px;
      color: #A1A5B7;
  }
  .container {
    border-bottom: #fff 2px ridge;
   }
   .disabledPagination{
     color: #e3e4e9;
   }
   .pagination {
    margin-top: 15px;
    // margin-left: -40px;
    display: flex;
    list-style: none;
    outline: none;
   }
   .pagination>.active>a {
    background: #FBEDEF 0% 0% no-repeat padding-box;
    color: #c52131;
    width: 24px;
height: 24px;
border-radius: 3px;
   }
   .pagination>li>a {
    border: 1px ;
    padding: 5px 10px;
    outline: none;
    cursor: pointer;
   }
   .summaryPanel{
    margin-top: -9.5% ;
    margin-left: 5%;
    z-index: 10;

   }

   .summary{
    font: normal normal normal 20px/90px Poppins;
    letter-spacing: 2px;
    color: #A1A5B7;
   }


  .verifiedMessage{
    display: inline-block;
    color:#25C997;
    font: normal normal normal 14px/21px Poppins;
    margin-left: 4px;
  }

}

@media only screen and (max-width: 1920) {
  body {
    max-width: 100%;
    max-height: 100%;
  }

  .customers_content {
    max-width: 100%;
    width: 100%;
  }

  .customers_card_body {
    max-width: 100%;
    width: 100%;
  }

  .cardCustomers {
    max-width: 100%;
    width: 100%;
  }
}

@media only screen and (min-width: 768px) {
  body {
    max-width: 100vw;
    max-height: 100%;
  }

  .cardCustomers {
    max-height: 100vh;
    height: 80%;
    min-width: 1029px;
    width: 100%;
    max-width: calc(100% - 310px);
  }
}
