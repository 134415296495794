body {
  background: #fffafb 0% 0% no-repeat padding-box;
}

.active {
  color: #25c997;
}

.inactive {
  color: #f64e60;
}

.apiPageContent {
  width: 100%;
  height: 100%;
}

.apiManagementCard {
  position: absolute;
  top: 111px;
  left: 280px;
  margin-left: 0px;
  width: 78%;
  min-height: 1134px;
  height: 80%;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 10px #00000005;
  border-radius: 10px;
  opacity: 1;
  z-index: 1;
}

.apicardbody {
  margin-top: 47px;
  margin-left: 36px;
  width: 99%;
}

.close {
  padding-top: 33px;
  padding-left: 165px;
}

.apiLabelText {
  text-align: left;
  font: normal normal 600 20px/24px Poppins;
  letter-spacing: 0px;
  color: #181c32;
  display: inline-block;
}

.searchFilterAPI {
  margin-left: 200px;
  display: inline-block;
}

.sIcon {
  position: absolute;
  left: 460px;
  top: 60px;
  width: 14px;
  height: 14px;
}

.search_bar {
  input:focus {
    outline: none;
  }

  width: 400px;
  justify-content: center;
  height: 42px;
  background: #f7f7f7 0% 0% no-repeat padding-box;
  border: 1px solid #eaeaea;
  border-radius: 3em;
  padding-left: 40px;
  font: normal normal normal 14px/21px Poppins;
  color: #7e8299;
  cursor: pointer;
}
input[type="submit"]{
  margin-left: -90px;
  height: 42px;
  width: 90px;
  background:transparent;
  font: normal normal bold 14px/21px Poppins;
  color: #c52131;
  border: 1px solid #c52131;
  border-radius: 3em;
 cursor: pointer;
  -webkit-appearance: none;
}

input[type="submit"]:hover{
  background-color: #c52131;
  color: white;
}
.searchFilterAPI .tooltiptext {
  visibility: hidden;
  width: 102px;
  background-color: black;
  font: normal normal normal 14px/21px Poppins;

  color: #fff;
  text-align: center;
  border-radius: 5px;
  padding: 4px;
  position: absolute;
  top: 10px;
  left: 480px;
  z-index: 1;
}

.searchFilterAPI:hover .tooltiptext {
  visibility: visible;
}

.apiGenerate {
  width: 30px;
  height: 30px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 3px;
  border: none;
  cursor: pointer;
  .viewIcon{
    width:24px;
  }

.a{
  fill:none;stroke:#c52131;stroke-linecap:round;stroke-linejoin:round;stroke-width:3px;
}

.apiGenerate:hover {
  background: #c52131;
    .viewIcon path{
      fill: white;
    }
  }

}

.keyButtonsCurrent{
  display: flex;
  margin-left: -40px;
}
.ag-theme-balham {
  margin-top: 20px;
  width: 100%;
  margin-left: 15px;
}

.ag-theme-balham .ag-header-cell-label {
  font: normal normal 600 14px/21px Poppins;
  letter-spacing: 0px;
  color: #181c32;
  opacity: 1;
}

.ag-theme-balham .ag-header-cell {
  background-color: white;
  font: normal normal 600 14px/21px Poppins;
  letter-spacing: 0px;
  color: #181c32;
  opacity: 1;
}

.ag-theme-balham .ag-header-cell-label:active {
  color: #f64e60;
}

.ag-theme-balham .ag-header-cell-label:focus {
  color: #f64e60;
}

.ag-theme-balham .ag-header-cell-label:hover {
  color: #f64e60;
}

.refreshButton {
  width: 30px;
  height: 30px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 3px;
  border: none;
  cursor: pointer;
}
.uIcon path{
fill: #f64e60;
}

.refreshButton:hover {
  background-color: #c52131;
  .uIcon path{
    fill: white;
  }
}

.ag-theme-balham .ag-paging-panel {
  position: inherit;
  color: #a1a5b7;
  margin-top: 20px;
  font: normal normal 600 12px/20px Poppins;
}

.ag-paging-button {
  color: #a1a5b7;
}

.ag-paging-row-summary-panel {
  text-align: left;
  padding-right: 450px;
  letter-spacing: 0px;
  margin-left: 0;
}

.ag-theme-balham .ag-icon-desc,
.ag-theme-balham .ag-icon-asc {
  font-weight: bold;
}

.ag-theme-balham .ag-icon-desc::before {
  color: #f64e60;
}

.ag-theme-balham .ag-icon-asc::before {
  color: #f64e60;
}

.keyModal {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0;
  bottom: 0;
  background: #35353533;
  display: flex;
  z-index: 10;
}

.key_content {
  width: 440px;
  height: 200px;
  justify-content: center;
  margin: auto;
  display: block;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 16px;
}

.key_title {
  text-align: center;
  font: normal normal 600 18px/30px Poppins;
  letter-spacing: 0px;
  color: #181c32;
  padding-top: 25px;
  padding-left: 20px;
  padding-right: 14px;
}

.keyInput textarea {
  font: normal normal 600 16px/30px Poppins;
  background-color: #c52131;
  margin-top: 10px;
  width: 96.5%;
  height: 40px;
  border-radius: 1px;
  padding-left: 14px;
  padding-top: 4px;
  color: white;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  resize: none;
}

.keyButtons {
  display: flex;
}

.copyButton {
  padding-top: 20px;
  padding-left: 180px;
}

.closeCopy {
  margin-top: 20px;
  margin-left: 18px;
  // border-radius: 25px;
}

.copySButton {
  padding-top: 20px;
  padding-left: 135px;
}

.closeC {
  font: normal normal 600 14px/21px Poppins;
  letter-spacing: 0px;
  border: none;
  padding-top: 10px;
  height: 36px;
}

.mainPa {
  margin: -4% 80%;
  padding: 5%;
  font: normal normal normal 14px/21px Poppins;
  font-size: 20px;
  color: #a1a5b7;
}

.container {
  border-bottom: #fff 2px ridge;
}
.disabledPagination{
  color: #e3e4e9;
}

.pagination {
  margin-top: 15px;
  display: flex;
  list-style: none;
  outline: none;
}

.pagination>.active>a {
  background: #FBEDEF 0% 0% no-repeat padding-box;
  color: #c52131;
  width: 24px;
  height: 24px;
  border-radius: 3px;
}

.pagination>li>a {
  border: 1px;
  padding: 5px 10px;
  outline: none;
  cursor: pointer;
}
.summaryPanel{
  margin-top: -9% ;
  margin-left: 2%;
  z-index: 10;

 }

 .summary{
  font: normal normal normal 20px/90px Poppins;
  letter-spacing: 2px;
  color: #A1A5B7;
 }

@media only screen and (max-width: 1920) {
  body {
    max-width: 100%;
    max-height: 100%;
  }

  .apiPageContent {
    max-width: 100%;
    width: 100%;
  }

  .apiManagementCard {
    max-height: 100vh;
    height: 80%;
    max-width: 100%;
    width: 100%;
  }

  .apicardbody {
    max-width: 100%;
    width: 99%;
  }
}

@media only screen and (min-width: 768px) {
  .apiManagementCard {
    max-height: 100vh;
    height: 80%;
    min-width: 1029px;
    width: 100%;
    max-width: calc(100% - 310px);
  }
}