@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@500;700&display=swap');
.dashboard_card {
  top: 180px;
  left: 125px;
  width: 100%;
  max-width: 503.33px;
  height: 127px;
  border-radius: 10px;
  opacity: 1;
  cursor: pointer;
}

.dashboard_card_body {
  text-align: left;
  margin-left: 24px;
  margin-top: 19px;

}
.dashboard_text {
font: normal normal medium 16px/20px Poppins;
  margin-top: 10px;
  letter-spacing: 0px;

}
.dashboard_number {
  padding-top: 14px;
  width: 34px;
  height: 68px;
  text-align: left;
  font: normal normal bold 26px/32px Poppins;
  letter-spacing: 0px;
  margin-bottom: 64px;
  opacity: 1;
}
