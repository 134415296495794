body {
  background: #fffafb 0% 0% no-repeat padding-box;
  width:100%;
}
.dashboard_content {
  width: 100%;
  height: 100%;

  .card_1 {
    position: absolute;
    top: 111px;
    left: 280px;
    width: calc(100% - 320px);
    height: 228px;
    background: #ffffff 0% 0% no-repeat padding-box;
    border-radius: 10px;
    box-shadow: 10px 10px 10px 10px #00000005;
    opacity: 1;
    z-index: 1;
    margin: 0 auto;
  }
  .card_body1 {
    text-align: left;
    padding-left: 1.5rem;
    margin-top: 25px;
  }

  .label_text {
    font: normal normal 300 16px/24px Poppins;
    letter-spacing: 0px;
    color: #181c32;
    opacity: 1;
    padding-top: 1px;
  }
  .userCards {
    display: flex;
    gap: 25px;
    margin-top: 1px;
    padding-top: 16px;
    padding-right: 25px;
  }

  .card_2 {
    position: absolute;
    top: 372px;
    left: 280px;
    width: calc(100% - 320px);
    height: 480px;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 20px 20px 20px 20px #00000005;
    border-radius: 15px;
    opacity: 1;
    z-index: 1;
    padding-bottom: 20px;
    margin-bottom: 50px;

    .chartData {
      margin-left: 10px;
    }
  }
  .card_body2 {
    text-align: left;
    padding-left: 1.5rem;
  }
  .label_text2 {
    font: normal normal 300 16px/26px Poppins;
    letter-spacing: 0px;
    color: #181c32;
    opacity: 1;
    margin-top: 27px;
  }
  .colorCodes {
    position: absolute;
    justify-content: flex-end;
    padding-top: 1px;
    padding-right: 1rem;
    margin-top: 14px;
    right: 10px;
    top: 10px;
  }

  .colorCodes ul li {
    list-style: none;
    position: relative;
  }
  .colorCodes ul li {
    display: inline-block;
    padding: 0px 20px;
    font: normal normal normal 13px/20px Poppins;
    letter-spacing: 0px;
    color: #3f4254;
    opacity: 1;
  }

  li {
    list-style: none;
    position: relative;
  }

  .all::before {
    content: '\2022';
    color: #f64e60;
    position: absolute;
    font-size: 2rem;
    left: -1px;
  }

  .active::before {
    content: '\2022';
    color: #25c997;
    position: absolute;
    font-size: 2rem;
    left: -0.1px;
  }

  .inactive::before {
    content: '\2022';
    color: #ffad44;
    position: absolute;
    font-size: 2rem;
    left: -0.1px;
  }
}

@media only screen and (min-width: 768px) {
  body {
    max-width: 100%;
    max-height: 100%;
  }
  .card_1 {
    min-height: 228px;
    height: 100%;
  }
  .card_2.chartData {
    max-width: 100vh;
    width: 100%;
  }
}
@media only screen and (max-width: 1930px) {
  .card_2 {
    margin-top: 10px;
    max-height: 800px;
    height: 70%;
  }
  .card_2.chartData {
    max-width: 100vh;
    width: 100%;
  }
}
