@import '~ag-grid-community/dist/styles/ag-theme-balham.css';

@import '~ag-grid-community/src/styles/ag-grid.scss';

@import '~ag-grid-community/src/styles/ag-theme-balham/sass/ag-theme-balham-mixin.scss';

.ag-theme-balham {
  @include ag-theme-balham(
    (
      border-color: white,
      range-selection-border-color: white,
      control-panel-background-color: white,
      checkbox-checked-color: red,

    )
  );
}
